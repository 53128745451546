<template>
    <div class="container">
      <a-collapse v-model:activeKey="activeKey" style="border-top-left-radius: 15px;border-top-right-radius: 15px;">
        <a-collapse-panel key="1" >
          <template #header>
            <h1>PARTNER</h1>
          </template>
          <div class="controller">
            <a-button @click="show_create_partner" type="primary">
              สร้างพาร์ทเนอร์
            </a-button>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <div class="wrapper">
        <!-- <a-card @click="show_create_partner()" class="big-card create-card" hoverable>
          <span class="material-symbols-outlined">add</span>
          <span>สร้างพาร์ทเนอร์</span>
        </a-card> -->
        <a-card class="big-card" v-for="{ id, name, address, tax_id, bank_name, bank_no, contact_name, contact_phone, contact_email, commission_day, commission_pay_day, remark }, i in partner_list" :key="id" hoverable >
            <template #actions>
              <a-tooltip placement="topLeft" title="แก้ไข">
                <span @click="show_create_partner(partner_list[i])" class="material-symbols-outlined">edit</span>
              </a-tooltip>
              <a-tooltip placement="topLeft" title="ลบ">
                <span @click="delete_partner(id)" class="material-symbols-outlined">delete</span>
              </a-tooltip>
            </template>
            <a-card-meta :title="name">
              <template #description>
                <p>ที่อยู่: {{ address }}</p>
                <p>เลขประจำตัวผู้เสียภาษี: {{ tax_id }}</p>
                <p>ชื่อธนาคาร: {{ bank_name }}</p>
                <p>เลขที่บัญชี: {{ bank_no }}</p>
                <p>ชื่อผู้ติดต่อ: {{ contact_name }}</p>
                <p>เบอร์โทรศัพท์: {{ contact_phone }}</p>
                <p>อีเมล: {{ contact_email }}</p>
                <p>วันที่จ่ายค่าคอมมิชชั่น: {{ commission_day }}</p>
                <p>วันที่จ่ายค่าคอมมิชชั่น: {{ commission_pay_day }}</p>
                <p>หมายเหตุ: {{ remark }}</p>
              </template>
              <template #avatar>
                <a-avatar src="https://joeschmoe.io/api/v1/random" />
              </template>
            </a-card-meta>
        </a-card>
      </div>

      <!-- Create Partner Drawer -->
      <a-drawer
        :closable="true"
        :visible="create_partner_modal"
        :destroyOnClose="true"
        :maskClosable="true"
        :width="600"
        title="สร้างพาร์ทเนอร์"
        @close="create_partner_modal = false"
      >
        <a-form
          :model="create_partner_form"
          layout="vertical"
          @finish="submit_create_partner"
        >
          <a-form-item name="name" label="ชื่อพาร์ทเนอร์">
            <a-input v-model:value="create_partner_form.name" />
          </a-form-item>

          <a-form-item name="address" label="ที่อยู่">
            <a-input v-model:value="create_partner_form.address" />
          </a-form-item>

          <a-form-item name="tax_id" label="เลขประจำตัวผู้เสียภาษี">
            <a-input v-model:value="create_partner_form.tax_id" />
          </a-form-item>

          <a-row justify="space-between">
            <a-col :span="11">
              <a-form-item name="bank_name" label="ชื่อธนาคาร">
                <a-input v-model:value="create_partner_form.bank_name" />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item name="bank_no" label="เลขที่บัญชี">
                <a-input v-model:value="create_partner_form.bank_no" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item name="contact_name" label="ชื่อผู้ติดต่อ">
            <a-input v-model:value="create_partner_form.contact_name" />
          </a-form-item>

          <a-form-item name="contact_phone" label="เบอร์โทรศัพท์">
            <a-input v-model:value="create_partner_form.contact_phone" />
          </a-form-item>

          <a-form-item name="contact_email" label="อีเมล">
            <a-input v-model:value="create_partner_form.contact_email" />
          </a-form-item>

          <a-row justify="space-between">
            <a-col :span="11">
              <a-form-item name="commission_day" label="วันที่คิดค่าคอมมิชชั่น">
                <a-input type="number" :min="1" :max="31" v-model:value="create_partner_form.commission_day" />
              </a-form-item>
            </a-col>
            <a-col :span="11">
              <a-form-item name="commission_pay_day" label="วันที่จ่ายค่าคอมมิชชั่น">
                <a-input type="number" :min="1" :max="31" v-model:value="create_partner_form.commission_pay_day" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-form-item name="remark" label="หมายเหตุ">
            <a-textarea v-model:value="create_partner_form.remark" />
          </a-form-item>

          <a-form-item>
            <a-button type="primary" html-type="submit">บันทึก</a-button>
          </a-form-item>
        </a-form>
      </a-drawer>
    </div>
  </template>
  
  <script>
  import AdminService from '../api/AdminService';
  import Swal from 'sweetalert2';
  
  export default {
    name: 'Partner',
    data () {
      return {
        partner_list: [],
        create_partner_modal: false,
        activeKey: ['1'],
        create_partner_form: {
          name: '',
          address: '',
          tax_id: '',
          bank_name: '',
          bank_no: '',
          contact_name: '',
          contact_phone: '',
          contact_email: '',
          commission_day: '',
          commission_pay_day: '',
          remark: ''
        }
      }
    },
    methods: {
      async get_order() {
        const res = await AdminService.get_model('Partner', { id: 'all', page: 1, perpage: 500 });
        this.partner_list = res.data
      },
      clear_form() {
        this.create_partner_form = {
          name: '',
          address: '',
          tax_id: '',
          bank_name: '',
          bank_no: '',
          contact_name: '',
          contact_phone: '',
          contact_email: '',
          commission_day: '',
          commission_pay_day: '',
          remark: ''
        }
      },
      show_create_partner(selected) {
        this.create_partner_modal = true
        if (selected) {
          this.create_partner_form = selected
        }
      },
      submit_create_partner() {
        const { id } = this.create_partner_form
        this.$message.loading({ content: 'Loading...', key: 'create_partner' });
        const {
          name,
          address,
          tax_id,
          bank_name,
          bank_no,
          contact_name,
          contact_phone,
          contact_email,
          commission_day,
          commission_pay_day,
          remark
        } = this.create_partner_form

        const body = {
          name,
          address,
          tax_id,
          bank_name,
          bank_no,
          contact_name,
          contact_phone,
          contact_email,
          commission_day,
          commission_pay_day,
          remark
        }

        if (id) {
          AdminService.update_model('Partner', id, { data: body })
            .then(() => {
              this.$message.success({ content: 'Update Success!', key: 'create_partner', duration: 1.5 });
              this.create_partner_modal = false
              this.clear_form()
              this.get_order()
            })
            .catch(() => {
              this.$message.error({ content: 'Update Failed!', key: 'create_partner', duration: 1.5 });
            })
        } else {
          AdminService.create_model('Partner', { data: [body] })
            .then(() => {
              this.$message.success({ content: 'Create Success!', key: 'create_partner', duration: 1.5 });
              this.create_partner_modal = false
              this.clear_form()
              this.get_order()
            })
            .catch(() => {
              this.$message.error({ content: 'Create Failed!', key: 'create_partner', duration: 1.5 });
            })
        }
      },
      delete_partner(id) {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#1890ff',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$message.loading({ content: 'Loading...', key: 'delete_partner' });
            AdminService.delete_model('Partner', id)
              .then(() => {
                this.$message.success({ content: 'Delete Success!', key: 'delete_partner', duration: 1.5 });
                this.get_order()
              })
              .catch(() => {
                this.$message.error({ content: 'Delete Failed!', key: 'delete_partner', duration: 1.5 });
              })
          }
        })
      }
    },
    mounted() {
      this.get_order()
    }
  }
  </script>
  